<template>
  <div>
    <!-- <Modal v-if="isByFeedOff" @hideModal="isByFeedOff = !isByFeedOff" /> -->
    <div class="user-header-page">
      <div class="user-header-box">
        <div class="user-header__smart-forex">
          <div class="hamburger-menu" @click="toggle">
            <div class="hamburger-lines">
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </div>
          </div>
          <div class="title" @click="$router.push({ name: 'home' })">
            <span>Smart Forex</span>
          </div>
        </div>
        <div class="user-header__icons">
          <div class="dropdown_lang">
            <b-dropdown :text="currentLang ? currentLang.toUpperCase() : 'EN'">
              <b-dropdown-item
                v-for="lang in avalibleLangs"
                @click="setSelectLanguage(lang)"
                key="lang"
                >{{ lang.toUpperCase() }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div class="item" @click="$emit('openNotification')">
            <img src="./img/bell.svg" alt="bell" />
            <div class="item_bell">
              <h5>{{ notification.length }}</h5>
            </div>
          </div>
          <div class="item" @click="$router.push({ name: 'trade' })">
            <img src="./img/candlestick.svg" alt="user" />
          </div>
          <div class="item" @click="$router.push({ name: 'account' })">
            <img src="./img/user.svg" alt="user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Modal from '../../views/global/account/modal/modalFeedOff'
export default {
  data() {
    return {}
  },
  components: {
    Modal,
  },
  computed: {
    ...mapState('App', ['showUserSidebar']),
    ...mapState('Langs', ['avalibleLangs']),
    ...mapState('Langs', ['currentLang']),
    ...mapState('User', ['pricing']),
    ...mapState('Notifications', ['notification']),
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_SIDEBAR']),
    ...mapMutations('Langs', ['SET_CURRENT_LANG']),

    toggle() {
      this.TOGGLE_USER_SIDEBAR(!this.showUserSidebar)
    },
    setSelectLanguage(data) {
      const storage = localStorage.getItem('userLang')
      if (data && storage) {
        localStorage.setItem('userLang', data)
        return this.SET_CURRENT_LANG(data)
      }
      if (!storage && data) {
        localStorage.setItem('userLang', data)
        return this.SET_CURRENT_LANG(data)
      }
      this.SET_CURRENT_LANG(storage ? storage : '')
    },
  },
  mounted() {
    this.setSelectLanguage()
  },
}
</script>
