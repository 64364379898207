<template>
  <div class="container_notification">
    <div class="card" v-for="notifi in notification" :key="notifi._id">
      <div class="header">
        <p>{{ notifi.message | intl }}</p>
        <p>{{ notifi.publishedAt }}</p>
      </div>
      <div class="body">
        <p>{{ notifi.title | intl }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'container_notification',
  computed: {
    ...mapState('Notifications', ['notification']),
  },
}
</script>

<style src="./notificationPanel.scss" lang="scss" />
