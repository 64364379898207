<template>
  <div class="user-layout" v-show="showLayout">
    <notifications />

    <div class="header">
      <userHeader @openNotification="openNotification" />
      <div v-show="showNotification">
        <NotificationPanel />
      </div>
    </div>
    <div class="body">
      <div
        class="sidebar-container mt-4 pt-2"
        :class="{ active: showUserSidebar }"
      >
        <Sidebar />
      </div>
      <router-view></router-view>
    </div>
    <div class="footer">
      <userFooter />
    </div>
  </div>
</template>

<script>
import userHeader from './UserHeader.vue'
import userFooter from './UserFooter.vue'
import Sidebar from './Sidebar.vue'
import NotificationPanel from './notificationPanel.vue'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    userHeader,
    userFooter,
    Sidebar,
    NotificationPanel,
  },

  data: () => ({
    ignoreRouteAuthNames: ['trade'],
    showNotification: false,
  }),

  computed: {
    ...mapState('App', ['showUserSidebar']),
    ...mapState('User', ['me']),

    showLayout() {
      return this.me || this.ignoreRouteAuthNames.includes(this.$route.name)
    },
    isNotification() {
      return this.showNotification
    },
  },
  methods: {
    ...mapActions('Langs', ['getAvailableLangs']),
    ...mapActions('Notifications', ['fetchNotifications']),
    ...mapActions('User', ['getCurrencyList', 'getUserWallet']),
    openNotification() {
      this.showNotification = !this.showNotification
    },
  },
  mounted() {
    this.fetchNotifications()
    this.getCurrencyList()
    this.getUserWallet()
    if (!this.me)
      if (!this.ignoreRouteAuthNames.includes(this.$route.name))
        this.$router.push({ name: 'sign-in' })
    this.getAvailableLangs()
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
