<template>
  <div class="sidebar-menu-page menu">
    <div class="sidebar-menu">
      <nav class="sidebar">
        <ul class="ul-menu">
          <li class="item">
            <div class="test">
              <div class="img">
                <img src="~@assets/img/home.svg" alt="home" />
              </div>
              <div class="text">
                <span>Dashboard</span>
              </div>
            </div>
            <ul class="dashboard submenu-item">
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <router-link to="cryptocurrency">Cryptocurrency</router-link>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <router-link to="deposits">Deposits</router-link>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <router-link to="referal">Referals</router-link>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
            </ul>
          </li>
          <li class="item">
            <div class="test">
              <div class="img">
                <img src="~@assets/img/ic_setting.svg" alt="setting" />
              </div>
              <div class="text">
                <span>Setting</span>
              </div>
            </div>
            <ul class="setting submenu-item">
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <router-link to='/user/reset'>Reset Password</router-link>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <router-link to="profile">Profile</router-link>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
            </ul>
          </li>
          <li class="item">
            <div class="test">
              <div class="img">
                <img src="~@assets/img/mail.svg" alt="mailbox" />
              </div>
              <div class="text">
                <span>Mailbox</span>
              </div>
            </div>
            <ul class="mailbox submenu-item">
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
            </ul>
          </li>
          <li class="item">
            <div class="test">
              <div class="img">
                <img src="~@assets/img/member.svg" alt="member" />
              </div>
              <div class="text">
                <span>Members</span>
              </div>
            </div>
            <ul class="members submenu-item">
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
            </ul>
          </li>
          <li class="item">
            <div class="test">
              <div class="img">
                <img src="~@assets/img/member.svg" alt="setting" />
              </div>
              <div class="text">
                <span>Setting</span>
              </div>
            </div>
            <ul class="setting submenu-item">
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
              <li>
                <i>
                  <img src="~@assets/img/arrow-right.svg" alt="arrow-right" />
                </i>
                <span>UI Forms</span>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      // isActive: true,
    }
  },

  computed: {
    // ...mapState('App', ['showUserSidebar']),
  },
}
</script>

<style lang="scss" src="./styles.scss">